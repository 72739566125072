.card-container {
  width: 70%;
  display: flow;
  margin: 0 auto;
  padding: 3rem;
  .App {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    .block {
      display: block;
      h1,
      .ant-tag {
        margin-top: 0;
        margin-bottom: 0;
        margin-left: 2rem;
      }
      p {
        margin-left: 2rem;
      }
    }
  }
  .img-container {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .button-container {
    display: flex;
    justify-content: space-evenly;
    width: 40%;
    margin: 3rem auto 1rem auto;
    // .ant-btn-primary {
    //   background-color: #011e57;
    // }
  }
}
.table {
  margin-top: 5rem;
}
table {
  background: #141414;
}
