.registro-container {
  padding-block: 7rem;
  h1 {
    margin: 0;
    text-align: center;
  }
  .form-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 8rem;
    a {
      color: white;
      text-decoration: underline;
      font-weight: 800;
      margin-left: 0.5rem;
    }
  }
}
.ant-select-selector {
  background: rgb(255 255 255 / 19%) !important;
}
