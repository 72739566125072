.nav-menu {
  ::after {
    border-bottom-color: #ff1616 !important;
    display: none;
  }
  :hover {
    color: #fff !important;
  }

  background-color: #000000;
  padding-block: 15px;
  .ant-menu-item {
    color: #fff;
  }
}
.ant-menu-item-selected {
  color: red !important;
}

.navbar-toggler {
  background-color: #ce0a2c;
  height: 55px;
}
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
@media (min-width: 350px) {
  .navbar-brand {
    width: 50%;
  }
}
