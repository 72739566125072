.ant-row {
    margin-inline: 30px;
  }
  
  .login {
    text-align: center;
  }
  body {
    // background: radial-gradient(circle, #3b0a12 0%, #000000 100%);
    color: #fff;
  }
  .ant-input-outlined {
    background: rgb(255 255 255 / 19%);
    height: 5vh;
    ::placeholder {
      color: #ffffff9a;
    }
    &:hover,
    &:focus {
      border-color: #620814 !important;
    }
  }
  .ant-select-selector {
    &:hover,
    &:focus {
      border-color: #620814 !important;
    }
  }
  .login-container {
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    a {
      color: white;
      text-decoration: underline;
    }
    .ant-btn-round {
      height: 6vh;
    }
  }
  .back {
    margin-top: 6rem;
  }
  
  .login-email {
    margin-bottom: 10px;
  }
  
  .login-pass {
    margin-bottom: 40px;
  }
  
  .form-container {
    position: relative;
    bottom: 75px;
    h2 {
      color: white;
    }
  }
  .banner-container {
    // background: #202020;
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      color: white;
      text-align: center;
    }
  }
  
  @media (max-width: 768px) {
    .ant-row {
    }
    .gana-con-conher-art {
      display: none;
    }
  }
  