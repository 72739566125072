@font-face {
  font-family: 'BentonSans';
  src: local('BentonSans'),
    url('./fonts/FontBentonSans/BentonSansWide-Regular.otf') format('truetype');
}
@font-face {
  font-family: 'BentonSans-Bold';
  src: local('BentonSans'),
    url('./fonts/FontBentonSans/BentonSansWide-Bold.otf') format('truetype');
}
@font-face {
  font-family: 'BentonSans-BigTitle';
  src: local('BentonSans'),
    url('./fonts/FontBentonSans/BentonSansWide-Black.otf') format('truetype');
}

body {
  margin: 0;
  font-family: 'BentonSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
p,
a,
.ant-btn-primary,
.ant-btn-default,
.ant-modal-title,
.ant-menu-title-content,
.ant-table > .ant-table-thead > .ant-table-cell,
.ant-typography {
  font-family: 'BentonSans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ant-menu-item-selected {
  background-color: transparent !important;
}
code {
  font-family: 'BentonSans';
}
