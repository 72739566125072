body {
  // background: radial-gradient(circle, #3b0a12 0%, #000000 100%);
  color: #fff;
}

// .navbar-toggler {
//   background-color: #ce0a2c;
//   height: 55px;
// }
// .navbar-toggler-icon {
//   background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
// }
.container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente */
  height: 110vh; /* Altura del contenedor */
  // overflow-y: scroll;
  // padding-top: 400px;
  &.justTopTen {
    height: 90vh;
    margin-top: 4rem;
    .hero {
      text-shadow: none;
    }
    .header{
      color: #E0202D;
    }
  }
}

.hero {
  font-size: 5rem;
  font-weight: 900;
  text-shadow: 1px 1px 10px rgb(0, 0, 0);
  font-family: 'BentonSans-BigTitle';
}

.header {
  color: rgba(255, 0, 0, 0.523);
  font-size: 1.1rem;
  letter-spacing: -1.5px;
  font-size: 2rem;
  font-family: 'BentonSans';
}

.table-container {
  flex: 1; /* Toma todo el espacio restante */
}
.top-3 {
  margin-top: 1.5rem;
  position: relative;
  .big-trophy {
    position: absolute;
    filter: blur(1px);
    width: 150px;
    left: -115px;
    z-index: -1;
  }
  .podium {
    position: absolute;
    width: 200px;
    top: 60px;
    right: -180px;
    z-index: -1;
  }
  .flag {
    position: absolute;
    width: 100px;
    right: -100px;
    top: -30px;
    z-index: 1;
  }
  .table-row {
    font-size: 1.25rem;
    font-weight: 900;
    // text-shadow: 1px 1px 10px rgb(0, 0, 0);
    width: 500px;
    height: 40px;
    // transform: skew(-10deg);
    // -webkit-transform: skew(-10deg);
    // -moz-transform: skew(-10deg);
    // -o-transform: skew(-10deg);
    background: #272727;
    margin-bottom: 1rem;
    // box-shadow: 1px 1px 10px #9a9a9a;
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
    align-items: center;
  }
  .first {
    background: linear-gradient(
      90deg,
      #e6ab01 0%,
      #e29d00 25%,
      #d68a00 50%,
      #f2b601 75%,
      #f39c01 100%
    );
    img {
      width: 20px;
    }
  }
  .second {
    background: linear-gradient(
      90deg,
      #acacac 0%,
      #b3b3b3 25%,
      #959494 50%,
      #9a9a99 75%,
      #6e6e6e 100%
    );
    img {
      width: 20px;
    }
  }
  .third {
    background: linear-gradient(
      90deg,
      #b95015 0%,
      #af4009 25%,
      #ce6a35 50%,
      #c55520 75%,
      #b44012 100%
    );
    img {
      width: 20px;
    }
  }
  .all {
    background: linear-gradient(
      90deg,
      #171717 0%,
      #000000 25%,
      #100e0d 50%,
      #110f0f 75%,
      #000000 100%
    );
    img {
      width: 20px;
    }
  }
}
.table-row {
  display: flex;
  justify-content: space-between;
}

.top-10 {
  font-size: 1.2rem;
  font-weight: 900;
  border: 1px solid rgba(255, 255, 255, 0.188);
  border-radius: 5px;
  padding: 20px 50px;
  width: 400px;
  // column-gap: 75px;
  // row-gap: 5px;
  // display: grid;
  // grid-template-rows: repeat(4, 1fr);
  // grid-auto-flow: column;
}

.center-div {
  width: 200px;
  height: 200px;
  background-color: lightblue;
}

.texto {
  color: white;
}

.nombres {
  font-family: 'BentonSans-Bold';
  text-align: left;
}

@media (max-width: 1000px) {
  .top-10 {
    padding: 20px 80px;
  }
  .table-row {
    font-size: 0.75rem;
  }
}

@media (max-width: 768px) {
  .hero {
    font-size: 4rem;
  }
  .header {
    font-size: 1.1rem;
  }
  .top-3 {
    .table-row {
      width: 400px;
    }
  }
}
@media (max-width: 576px) {
  .hero {
    font-size: 3rem;
  }
  .header {
    font-size: 1rem;
  }
  .top-3 {
    .table-row {
      width: 300px;
      font-size: 1rem;
    }
  }
}
