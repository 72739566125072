.admin-container {
  width: 80%;
  margin: 0 auto;
  overflow-x: auto;
  padding: 4rem 0 0rem 0rem;
  .table {
    width: 100%;
    margin-top: 2rem;
  }
  table {
    background: #141414;
  }
}
.ant-table-thead > tr > th {
  background: rgb(0 0 0 / 80%) !important;
}
