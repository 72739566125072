.user-container {
  overflow-y: auto;
  h1 {
    padding: 1rem 0 5rem 5rem;
  }
  .ant-table-wrapper {
    margin: 0px auto 100px auto;
    max-width: 900px;
  }
  .table {
    width: 90%;
  }
  table {
    background: #141414;
  }
}
